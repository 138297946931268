export default [
  {
    inputs: [
      {
        internalType: 'contract ISubAccounts',
        name: '_subAccounts',
        type: 'address',
      },
      {
        internalType: 'contract IMatching',
        name: '_matching',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20BasedAsset',
        name: 'baseAsset',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'initDeposit',
        type: 'uint256',
      },
      {
        internalType: 'contract IManager',
        name: 'manager',
        type: 'address',
      },
    ],
    name: 'createAndDepositSubAccount',
    outputs: [
      {
        internalType: 'uint256',
        name: 'accountId',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'matching',
    outputs: [
      {
        internalType: 'contract IMatching',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'subAccounts',
    outputs: [
      {
        internalType: 'contract ISubAccounts',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const
