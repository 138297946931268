import { BatchUserOperationCallData } from '@alchemy/aa-core'
import fetchSessionKey from '@lyra/core/api/private/fetchSessionKey'
import { LyraAuthHeaders } from '@lyra/core/constants/api'
import matchingAbi from '@lyra/web/abis/matchingAbi'
import { lyraContractAddresses } from '@lyra/web/constants/contracts'
import { Address, encodeFunctionData, PrivateKeyAccount } from 'viem'

import sleep from '../sleep'
import { getUtcSecs } from '../time'

const POLLING_INTERVAL_MS = 500

const tryFetchIsSessionKeyRegistered = async (
  walletAddress: Address,
  sessionKeyAddress: Address,
  authHeaders: LyraAuthHeaders
) => {
  try {
    const sessionKey = await fetchSessionKey(
      { wallet: walletAddress, sessionKey: sessionKeyAddress },
      authHeaders,
      { cache: 'no-store' }
    )
    return !!sessionKey
  } catch (error) {
    // returns 401 or rpc error if not registered
    return false
  }
}

export async function waitForSessionKeyRegistration(
  walletAddress: Address,
  sessionKeyAddress: Address,
  authHeaders: LyraAuthHeaders,
  pollMs = 15_000 // 15 seconds
) {
  const isActive = await tryFetchIsSessionKeyRegistered(
    walletAddress,
    sessionKeyAddress,
    authHeaders
  )
  if (!isActive && pollMs > 0) {
    await sleep(POLLING_INTERVAL_MS)
    return waitForSessionKeyRegistration(
      walletAddress,
      sessionKeyAddress,
      authHeaders,
      pollMs - POLLING_INTERVAL_MS
    )
  }
  return isActive
}

export const getRegisterSessionKeyTx = (
  sessionKeyAddress: Address,
  expiry: Date
): BatchUserOperationCallData[0] => {
  const expirySecs = BigInt(getUtcSecs(expiry))
  return {
    target: lyraContractAddresses.matching,
    data: encodeFunctionData({
      abi: matchingAbi,
      functionName: 'registerSessionKey',
      args: [sessionKeyAddress, expirySecs],
    }),
  }
}

export const getRevokeSessionKeyTx = (
  sessionKeyAddress: Address
): BatchUserOperationCallData[0] => {
  return {
    target: lyraContractAddresses.matching,
    data: encodeFunctionData({
      abi: matchingAbi,
      functionName: 'deregisterSessionKey',
      args: [sessionKeyAddress],
    }),
  }
}

export const signSessionKeyHeaders = async (
  address: Address,
  sessionKey: PrivateKeyAccount
): Promise<LyraAuthHeaders> => {
  const timestamp = Date.now().toString()
  const signature = await sessionKey.signMessage({ message: timestamp })

  return {
    'X-LyraWallet': address,
    'X-LyraSignature': signature,
    'X-LyraTimestamp': timestamp,
  }
}
