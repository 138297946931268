import isServer from '@lyra/core/utils/isServer'
import spindl from '@spindl-xyz/attribution'
import { Address } from 'viem'

import { isDev, isPreview } from '../constants/env'

export const recordSpindlEvent = async (
  eventName: string,
  properties?: Record<string, any>,
  identity?: { address?: Address; ownerAddress?: Address }
) => {
  if (isDev || isPreview) {
    return
  }
  const sdkKey = process.env.NEXT_PUBLIC_SPINDL_API_KEY
  if (!sdkKey) {
    console.warn('Failed to initialize Spindl SDK for event', eventName)
    return
  }
  const address = identity?.address
  const ownerAddress = identity?.ownerAddress
  try {
    spindl.configure({ sdkKey })
    if (isServer) {
      // for vercel logging
      console.log(`[spindl] ${eventName}`, { properties, address, ownerAddress })
    } else {
      console.debug(`[spindl] ${eventName}`, { properties, address, ownerAddress })
    }
    await spindl.track(eventName, properties, { address, customerUserId: ownerAddress })
  } catch (error) {
    console.warn('Failed to track Spindl custom event', eventName, {
      properties,
      address,
      ownerAddress,
    })
  }
}
