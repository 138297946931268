'use client'

import React, { useCallback, useMemo, useState } from 'react'

import emptyFunction from '../utils/emptyFunction'

type Props = {
  isAdmin: boolean
  isDeployLocked: boolean
  children?: React.ReactNode
}

export type AdminContext = {
  isDeployLocked: boolean
  isAdmin: boolean
  unlockAdmin: (password: string) => Promise<boolean>
  unlockDeploy: (password: string) => Promise<boolean>
}

export const AdminContext = React.createContext<AdminContext>({
  isDeployLocked: true,
  isAdmin: false,
  unlockAdmin: emptyFunction as any,
  unlockDeploy: emptyFunction as any,
})

export default function AdminProvider({
  isAdmin: isInitialAdmin,
  isDeployLocked: initialIsDeployLocked,
  children,
}: Props) {
  const [isDeployLocked, setIsDeployLocked] = useState(initialIsDeployLocked)
  const [isAdmin, setIsAdmin] = useState(isInitialAdmin)

  const unlockDeploy = useCallback(async (password: string) => {
    const verifyRes = await fetch('/api/intern/deploy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    })
    if (verifyRes.ok) {
      setIsDeployLocked(false)
      return true
    } else {
      throw new Error('Deploy auth failed')
    }
  }, [])

  const unlockAdmin = useCallback(async (password: string) => {
    const verifyRes = await fetch('/api/intern/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    })
    if (verifyRes.ok) {
      setIsAdmin(true)
      return true
    } else {
      throw new Error('Admin auth failed')
    }
  }, [])

  const value = useMemo(() => {
    return {
      isAdmin,
      isDeployLocked,
      unlockDeploy,
      unlockAdmin,
    }
  }, [isAdmin, isDeployLocked, unlockDeploy, unlockAdmin])

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
}
