import { LyraAuthHeaders } from '@lyra/core/constants/api'

import { User } from '../constants/user'

export default function getAuthHeaders(user: User): LyraAuthHeaders {
  return {
    'X-LyraWallet': user.address,
    'X-LyraTimestamp': user.skTimestamp.toString(),
    'X-LyraSignature': user.skSignature,
  }
}
