'use client'

import { createConfig, WagmiProvider } from '@privy-io/wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { http } from 'wagmi'

import { arbitrumChain, baseChain, mainnetChain, optimismChain } from '../../constants/chains'
import LyraPrivyProvider from './PrivyProvider'

export const wagmiConfig = createConfig({
  chains: [mainnetChain, arbitrumChain, baseChain, optimismChain],
  transports: {
    [mainnetChain.id]: http(mainnetChain.rpcUrls.default.http[0]),
    [arbitrumChain.id]: http(arbitrumChain.rpcUrls.default.http[0]),
    [optimismChain.id]: http(optimismChain.rpcUrls.default.http[0]),
    [baseChain.id]: http(baseChain.rpcUrls.default.http[0]),
  },
  ssr: true,
})

const queryClient = new QueryClient()

type Props = {
  children?: React.ReactNode
}

export default function WalletProvider({ children }: Props) {
  return (
    <LyraPrivyProvider>
      <QueryClientProvider client={queryClient}>
        <WagmiProvider config={wagmiConfig}>{children}</WagmiProvider>
      </QueryClientProvider>
    </LyraPrivyProvider>
  )
}
