'use client'

import React, { useRef } from 'react'

type Props = {
  orderbookTime: number
  children: React.ReactNode
}

type OrderbookTimeContext = number

export const OrderbookTimeContext = React.createContext<number>(0)

export default function OrderbookTimeProvider({ orderbookTime, children }: Props) {
  // we never want clientTime to change
  const clientTime = useRef(Date.now()).current
  const serverTimeOffset = orderbookTime - clientTime
  return (
    <OrderbookTimeContext.Provider value={serverTimeOffset}>
      {children}
    </OrderbookTimeContext.Provider>
  )
}
