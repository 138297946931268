import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateChangeSubaccountLabelParamsSchema,
  PrivateChangeSubaccountLabelResponseSchema,
} from '../types/private.change_subaccount_label'

export default async function changeSubaccountLabel(
  params: PrivateChangeSubaccountLabelParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateChangeSubaccountLabelResponseSchema> {
  return tryRequest<
    PrivateChangeSubaccountLabelParamsSchema,
    PrivateChangeSubaccountLabelResponseSchema
  >('/private/change_subaccount_label', params, {
    ...options,
    authHeaders,
  })
}
