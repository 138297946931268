import useHover from '@lyra/core/hooks/useHover'
import { Check } from '@tamagui/lucide-icons'
import React from 'react'
import { StackProps, useTheme, XStack } from 'tamagui'

import LabelText from '../LabelText'
import CheckboxFrame from './styles'

export type CheckboxProps = {
  isChecked: boolean
  label?: string
  isDisabled?: boolean
  onPress?: () => void
} & StackProps

const Checkbox = ({ isChecked, label, isDisabled, onPress, ...stackProps }: CheckboxProps) => {
  const theme = useTheme()
  const { isHovering, handleHoverIn, handleHoverOut } = useHover()
  return (
    <XStack
      {...stackProps}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      gap="$1"
      alignItems="flex-start"
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      onPress={onPress}
    >
      <CheckboxFrame
        minWidth={20}
        minHeight={20}
        maxWidth={20}
        maxHeight={20}
        disabled={isDisabled}
        checked={isChecked}
        borderColor={isHovering || isChecked ? '$primaryText' : '$hairline'}
      >
        {isChecked ? <Check color={theme.invertedText?.get()} size={16} /> : null}
      </CheckboxFrame>
      {label ? (
        <LabelText flexGrow={1} flexShrink={1}>
          {label}
        </LabelText>
      ) : null}
    </XStack>
  )
}

Checkbox.displayName = 'Checkbox'

export default Checkbox
