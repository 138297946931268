import { useCallback, useContext } from 'react'
import useSWR from 'swr'

import { OrderbookTimeContext } from '../providers/OrderbookTimeProvider'
import { getUtcSecs } from '../utils/time'

export default function useOrderbookTimestamp(): {
  utcTimestampSec: number
  getTimestamp: () => number
  getUtcTimestampSec: () => number
} {
  const orderbookTimeOffset = useContext(OrderbookTimeContext)
  const getUtcTimestampSec = useCallback(
    () => getUtcSecs(new Date(Date.now() + orderbookTimeOffset)),
    [orderbookTimeOffset]
  )
  const getTimestamp = useCallback(
    () => new Date().getTime() + orderbookTimeOffset,
    [orderbookTimeOffset]
  )

  const { data: utcTimestampSec } = useSWR('OrderbookUtcTimestamp', getUtcTimestampSec, {
    refreshInterval: 1000,
    fallbackData: getUtcTimestampSec(),
  })

  return {
    utcTimestampSec,
    getTimestamp,
    getUtcTimestampSec,
  }
}
