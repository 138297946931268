import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateSessionKeysParamsSchema,
  PrivateSessionKeysResponseSchema,
} from '../types/private.session_keys'

export default function fetchSessionKeys(
  params: PrivateSessionKeysParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
) {
  return tryRequest<PrivateSessionKeysParamsSchema, PrivateSessionKeysResponseSchema>(
    '/private/session_keys',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
