import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateEditSessionKeyParamsSchema,
  PrivateEditSessionKeyResponseSchema,
} from '../types/private.edit_session_key'

export default async function editSessionKey(
  params: PrivateEditSessionKeyParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateEditSessionKeyResponseSchema> {
  return tryRequest<PrivateEditSessionKeyParamsSchema, PrivateEditSessionKeyResponseSchema>(
    '/private/edit_session_key',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
