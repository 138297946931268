import { ButtonProps } from '@lyra/core/components/Button'
import TrackButton from '@lyra/web/components/common/TrackButton'
import useAuth from '@lyra/web/hooks/useAuth'
import { useState } from 'react'

type Props = Omit<ButtonProps, 'label'>

export default function SignInButton({ onPress, isDisabled, ...buttonProps }: Props) {
  const { isAuthenticated, isAuthenticating, login } = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <TrackButton
      {...buttonProps}
      isDisabled={isAuthenticated || isDisabled}
      eventName={'sign-in-button'}
      label={isAuthenticating ? 'Connecting' : 'Connect'}
      isLoading={isAuthenticating || isLoading}
      onPress={async (e) => {
        try {
          setIsLoading(true)
          if (onPress) {
            await onPress(e)
          }
          await login()
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      }}
    />
  )
}
