import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateRegisterScopedSessionKeyParamsSchema,
  PrivateRegisterScopedSessionKeyResponseSchema,
} from '../types/private.register_scoped_session_key'

export default async function registerScopedSessionKey(
  params: PrivateRegisterScopedSessionKeyParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateRegisterScopedSessionKeyResponseSchema> {
  return tryRequest<
    PrivateRegisterScopedSessionKeyParamsSchema,
    PrivateRegisterScopedSessionKeyResponseSchema
  >('/private/register_scoped_session_key', params, {
    ...options,
    authHeaders,
  })
}
