import Button from '@lyra/core/components/Button'
import Checkbox from '@lyra/core/components/Checkbox'
import Link from '@lyra/core/components/Link'
import Modal from '@lyra/core/components/Modal'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import { PageId } from '@lyra/web/constants/pages'
import useAuth from '@lyra/web/hooks/useAuth'
import emptyFunction from '@lyra/web/utils/emptyFunction'
import { getPagePath } from '@lyra/web/utils/pages'
import { useCallback, useState } from 'react'

export default function TermsOfUseModal() {
  const { mutate, logout } = useAuth()
  const [error, setError] = useState<string | null>(null)
  const [isChecked, setIsChecked] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)

  const handleSubmit = useCallback(async () => {
    try {
      await fetch('/api/acknowledge-terms', { method: 'POST' })
      await mutate()
    } catch (_error) {
      setError('Something went wrong. Please try again')
    }
  }, [mutate])

  return (
    <Modal
      onClose={emptyFunction}
      title="Acknowledge Terms"
      isLarge
      subtitle={
        <>
          Check the boxes to confirm your agreement to the{' '}
          <Link
            target="_blank"
            href={getPagePath({ page: PageId.TermsOfUse })}
            label="Terms of Use"
          />{' '}
          and{' '}
          <Link
            target="_blank"
            href={getPagePath({ page: PageId.PrivacyPolicy })}
            label="Privacy Policy"
          />
          :
        </>
      }
      // IMPORTANT!! User MUST acknowledge terms and privacy policy
      disableClose
    >
      <Section.XStack>
        <Checkbox
          isChecked={isChecked}
          onPress={() => setIsChecked(!isChecked)}
          label="I acknowledge that I have read, understand and agree to the Terms of Use. I understand the
          risks and disclaimers, and understand that we have no control over your assets or the Derive
          Protocol."
          width="100%"
        />
      </Section.XStack>
      <Section.XStack>
        <Checkbox
          isChecked={isChecked2}
          onPress={() => setIsChecked2(!isChecked2)}
          label="
          I am not a resident, citizen or company incorporated in the United States of America,
          Australia or any other restricted region."
          width="100%"
        />
      </Section.XStack>
      <Section.YStack>
        {error ? <Notice status="error" message="error" width="100%" /> : null}
        <Button
          size="lg"
          isSolid
          color="cta"
          label="Agree and Continue"
          isDisabled={!isChecked || !isChecked2}
          onPress={handleSubmit}
        />
        <Button size="lg" label="Disconnect" onPress={logout} />
      </Section.YStack>
    </Modal>
  )
}
