import { SECONDS_IN_DAY } from '@lyra/core/constants/time'
import isServer from '@lyra/core/utils/isServer'
import { LOCAL_STORAGE_SESSION_KEY } from '@lyra/web/constants/localStorage'
import { MAX_ORDER_EXPIRY_DAYS } from '@lyra/web/constants/order'
import { DeviceSessionKey } from '@lyra/web/constants/sessionKeys'
import { Address, PrivateKeyAccount } from 'viem'
import { privateKeyToAccount } from 'viem/accounts'

// Note: session expiry timestamp must add max order expiry as a buffer
export const SESSION_KEY_EXPIRY_DAYS = 180 + MAX_ORDER_EXPIRY_DAYS // 90 days
export const SESSION_KEY_EXPIRY_SECS = SESSION_KEY_EXPIRY_DAYS * SECONDS_IN_DAY

const getLocalSessionKeyLabel = (sessionKey: Address) =>
  `${LOCAL_STORAGE_SESSION_KEY}-${sessionKey}`

function bufferToBase64(buffer: ArrayBufferLike) {
  const binary = String.fromCharCode(...new Uint8Array(buffer))
  return btoa(binary)
}

function base64ToBuffer(base64: string) {
  const binaryString = atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

export const generateSessionKeySecret = async () => {
  return await crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256, // can be 128, 192, or 256
    },
    true, // whether the key is extractable
    ['encrypt', 'decrypt'] // can be used to encrypt and decrypt
  )
}

export const exportSessionKeySecret = async (key: CryptoKey) => {
  const exported = await crypto.subtle.exportKey('raw', key)
  return bufferToBase64(exported)
}

const importSessionKeySecret = async (base64Key: string) => {
  const keyBuffer = base64ToBuffer(base64Key)
  return await window.crypto.subtle.importKey('raw', keyBuffer, { name: 'AES-GCM' }, true, [
    'encrypt',
    'decrypt',
  ])
}

const decryptSessionPrivateKey = async (base64Data: string, key: CryptoKey) => {
  const combinedData = base64ToBuffer(base64Data)
  const iv = combinedData.slice(0, 12) // Extract the IV
  const encryptedData = combinedData.slice(12) // Extract the encrypted data

  const decryptedData = await window.crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: iv },
    key,
    encryptedData
  )

  return new TextDecoder().decode(decryptedData)
}

export const findDeviceSessionKeyInLocalStorage = async (sessionKeys: DeviceSessionKey[]) => {
  if (isServer) {
    return null
  }
  const localSessionKeys: (PrivateKeyAccount | undefined)[] = await Promise.all(
    sessionKeys.map(async (sessionKey) => {
      const encryptedPrivateKey = window.localStorage.getItem(
        getLocalSessionKeyLabel(sessionKey.address)
      )
      if (!encryptedPrivateKey) {
        return undefined
      }
      try {
        if (!sessionKey.secret) {
          console.debug(
            'device session key deleted, removing from localStorage',
            sessionKey.address
          )
          window.localStorage.removeItem(getLocalSessionKeyLabel(sessionKey.address))
          return undefined
        }

        // import secret key
        const key = await importSessionKeySecret(sessionKey.secret)

        // decrypt private key
        const privateKey = await decryptSessionPrivateKey(encryptedPrivateKey, key)

        // convert to public key
        const sessionKeyObj = privateKeyToAccount(privateKey as `0x${string}`)

        // check public key
        if (sessionKeyObj.address !== sessionKey.address) {
          // public keys do not match
          return undefined
        }

        return sessionKeyObj
      } catch (error) {
        // invalid private key, cleanup
        console.warn(
          'failed to decrypt session key, removing from localStorage',
          sessionKey.address
        )
        window.localStorage.removeItem(getLocalSessionKeyLabel(sessionKey.address))
        return undefined
      }
    })
  )

  return localSessionKeys.find((obj) => !!obj) ?? null
}

const encryptSessionPrivateKey = async (sessionPrivateKey: `0x${string}`, key: CryptoKey) => {
  const encodedData = new TextEncoder().encode(sessionPrivateKey)
  const iv = window.crypto.getRandomValues(new Uint8Array(12)) // Initialization vector

  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv,
    },
    key,
    encodedData
  )

  // Combine the IV and the encrypted data and convert to Base64
  const combinedData = new Uint8Array(iv.length + encryptedData.byteLength)
  combinedData.set(new Uint8Array(iv), 0)
  combinedData.set(new Uint8Array(encryptedData), iv.length)

  return bufferToBase64(combinedData)
}

export const storeSessionPrivateKey = async (sessionPrivateKey: `0x${string}`, key: CryptoKey) => {
  const sessionKey = privateKeyToAccount(sessionPrivateKey)
  const encryptedPrivateKey = await encryptSessionPrivateKey(sessionPrivateKey, key)
  window.localStorage.setItem(getLocalSessionKeyLabel(sessionKey.address), encryptedPrivateKey)
}

export const saveDeviceSessionKey = async (
  sessionKeyAddress: Address,
  sessionkeySecret: string
) => {
  const res = await fetch('/api/session-key', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionKey: sessionKeyAddress,
      sessionKeySecret: sessionkeySecret,
    }),
  })
  return res.ok
}
