import Button from '@lyra/core/components/Button'
import Modal from '@lyra/core/components/Modal'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import { useIntercom } from 'react-use-intercom'

type Props = {
  errorMessage?: string
  onClose: () => void
}

export default function AuthErrorModal({ errorMessage, onClose }: Props) {
  const { show } = useIntercom()

  return (
    <Modal
      onClose={onClose}
      title="Failed to authenticate wallet"
      subtitle="Please disconnect your wallet and try again. If the error persists, contact support."
    >
      {errorMessage ? (
        <Section.XStack>
          <Notice message={errorMessage} status="error" width="100%" />
        </Section.XStack>
      ) : null}
      <Section.YStack>
        <Button label="Contact Support" size="lg" isSolid color="red" onPress={show} />
        <Button label="Disconnect" size="lg" onPress={onClose} />
      </Section.YStack>
    </Modal>
  )
}
