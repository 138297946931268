import Button, { ButtonProps } from '@lyra/core/components/Button'
import useSpindl from '@lyra/web/hooks/useSpindl'
import { removeQueryAndHashFromPathname } from '@lyra/web/utils/pages'
import { usePathname } from 'next/navigation'
import React, { useCallback } from 'react'
import { GestureResponderEvent } from 'react-native'

export type TrackButtonProps = ButtonProps & {
  eventName?: string
  eventProperties?: Record<string, any>
}

export default function TrackButton({
  eventName,
  eventProperties,
  onPress,
  ...buttonProps
}: TrackButtonProps) {
  const { recordEvent } = useSpindl()

  const pathname = usePathname()

  const handlePress = useCallback(
    (e: GestureResponderEvent) => {
      if (eventName) {
        recordEvent(eventName, {
          pathname: removeQueryAndHashFromPathname(pathname),
          ...eventProperties,
        })
      }
      if (onPress) {
        return onPress(e)
      }
    },
    [eventName, eventProperties, onPress, pathname, recordEvent]
  )

  return <Button onPress={handlePress} {...buttonProps} />
}
