import { Address } from 'viem'

import { LyraAuthHeaders } from '../../constants/api'
import { RequestOptions } from '../tryRequest'
import { SessionKeyResponseSchema } from '../types/private.session_keys'
import fetchSessionKeys from './fetchSessionKeys'

type Params = {
  wallet: Address
  sessionKey: Address
}

export default async function fetchSessionKey(
  { wallet, sessionKey }: Params,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<SessionKeyResponseSchema | undefined> {
  const {
    result: { public_session_keys },
  } = await fetchSessionKeys({ wallet }, authHeaders, options)
  return public_session_keys.find((obj) => obj.public_session_key === sessionKey)
}
