import { LyraAuthHeaders } from '../../constants/api'
import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateGetAllPortfoliosParamsSchema,
  PrivateGetAllPortfoliosResponseSchema,
} from '../types/private.get_all_portfolios'

export default async function fetchAllPortfolios(
  params: PrivateGetAllPortfoliosParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateGetAllPortfoliosResponseSchema> {
  return tryRequest<PrivateGetAllPortfoliosParamsSchema, PrivateGetAllPortfoliosResponseSchema>(
    '/private/get_all_portfolios',
    params,
    { ...options, authHeaders }
  )
}
