import { useCallback, useMemo } from 'react'

import { recordSpindlEvent } from '../utils/spindl'
import useAuth from './useAuth'

type Spindl = {
  recordEvent: (eventName: string, properties?: Record<string, any>) => Promise<void>
}

export default function useSpindl(): Spindl {
  const { user } = useAuth()
  const address = user?.address
  const ownerAddress = user?.ownerAddress
  const recordEvent = useCallback(
    async (eventName: string, properties?: Record<string, any>) =>
      await recordSpindlEvent(eventName, properties, { address, ownerAddress }),
    [address, ownerAddress]
  )
  return useMemo(() => ({ recordEvent }), [recordEvent])
}
