import { LyraAuthHeaders } from '../../constants/api'
import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateGetSubaccountsParamsSchema,
  PrivateGetSubaccountsResponseSchema,
} from '../types/private.get_subaccounts'

export default async function fetchSubaccountIds(
  params: PrivateGetSubaccountsParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateGetSubaccountsResponseSchema> {
  return tryRequest<PrivateGetSubaccountsParamsSchema, PrivateGetSubaccountsResponseSchema>(
    '/private/get_subaccounts',
    params,
    { ...options, authHeaders }
  )
}
