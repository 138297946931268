import useSWR from 'swr'
import { PrivateKeyAccount } from 'viem'

import { DeviceSessionKey } from '../constants/sessionKeys'
import { findDeviceSessionKeyInLocalStorage } from '../utils/client/deviceSessionKey'
import useAuth from './useAuth'

const fetcher = async (): Promise<PrivateKeyAccount | null> => {
  const res = await fetch('/api/session-key')

  if (!res.ok) {
    return null
  }

  const deviceSessionKeys = (await res.json()) as DeviceSessionKey[]

  const sessionKey = await findDeviceSessionKeyInLocalStorage(deviceSessionKeys)

  return sessionKey
}

export default function useLocalSessionKey() {
  const { user } = useAuth()
  const wallet = user?.ownerAddress
  return useSWR(['LocalSessionKey', wallet], () => (wallet ? fetcher() : null), {
    revalidateOnFocus: false,
  })
}
