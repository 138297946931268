import useHover from '@lyra/core/hooks/useHover'
import { BodyTextSize, TextColor } from '@lyra/core/utils/text'
import { AlertOctagon, AlertTriangle, CheckCircle2, Info, X } from '@tamagui/lucide-icons'
import React from 'react'
import { StackProps, XStack } from 'tamagui'

import BodyText from '../BodyText'
import Icon from '../Icon'
import Link from '../Link'

export type NoticeStatus = 'success' | 'error' | 'warning' | 'info'

export type NoticeProps = {
  message: React.ReactNode
  status?: NoticeStatus
  learnMoreHref?: string
  learnMoreTarget?: string
  learnMoreLabel?: string
  onClose?: () => void
  textSize?: BodyTextSize
} & StackProps

const getBgColor = (status: NoticeStatus): string => {
  switch (status) {
    case 'success':
      return '$greenBg'
    case 'error':
      return '$redBg'
    case 'warning':
      return '$amberBg'
    case 'info':
      return '$translucentBg'
  }
}

const getBorderColor = (status: NoticeStatus): string => {
  switch (status) {
    case 'success':
      return '$greenHairline'
    case 'error':
      return '$redHairline'
    case 'warning':
      return '$amberHairline'
    case 'info':
      return '$hairline'
  }
}

const getTextColor = (status: NoticeStatus): TextColor => {
  switch (status) {
    case 'success':
      return 'green'
    case 'error':
      return 'red'
    case 'warning':
      return 'amber'
    case 'info':
      return 'primary'
  }
}

const getIconSize = (textSize: BodyTextSize) => {
  switch (textSize) {
    case 'sm':
      return 14
    case 'md':
      return 16
    case 'lg':
      return 18
  }
}

const Notice = ({
  message,
  status = 'info',
  learnMoreHref,
  learnMoreLabel,
  learnMoreTarget,
  onClose,
  textSize = 'md',
  ...stackProps
}: NoticeProps) => {
  const color = getTextColor(status)

  const { isHovering, handleHoverIn, handleHoverOut } = useHover()

  const iconSize = getIconSize(textSize)

  return (
    <XStack
      width="fit-content"
      maxWidth="100%" // avoid overflow
      {...stackProps}
      padding="$1.5"
      backgroundColor={getBgColor(status)}
      borderRadius="$1"
      borderColor={getBorderColor(status)}
      borderWidth={1}
      style={{ textDecoration: 'none' }}
      gap="$1"
    >
      <XStack minWidth={iconSize} maxWidth={iconSize} marginTop="1.25px">
        <Icon
          size={iconSize}
          color={color}
          icon={
            status === 'success' ? (
              <CheckCircle2 />
            ) : status === 'warning' ? (
              <AlertTriangle />
            ) : status === 'error' ? (
              <AlertOctagon />
            ) : (
              <Info />
            )
          }
        />
      </XStack>
      <BodyText size={textSize} flexGrow={1} flexShrink={1} color={color}>
        {message}
        {learnMoreHref ? (
          <>
            &nbsp;
            <Link
              href={learnMoreHref}
              target={learnMoreTarget}
              label={learnMoreLabel ?? 'Learn more'}
            />
          </>
        ) : null}
      </BodyText>
      {onClose ? (
        <XStack
          cursor="pointer"
          onPress={onClose}
          minWidth={iconSize}
          maxWidth={iconSize}
          marginTop="1.25px"
          onHoverIn={handleHoverIn}
          onHoverOut={handleHoverOut}
          opacity={isHovering ? 1.0 : 0.5}
        >
          <Icon size={iconSize} color={color} icon={<X />} />
        </XStack>
      ) : null}
    </XStack>
  )
}

Notice.displayName = 'Notice'

export default Notice
