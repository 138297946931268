import { Stack, styled } from 'tamagui'

export default styled(Stack, {
  name: 'Checkbox',
  borderRadius: '$1',
  borderWidth: 1,
  borderColor: '$hairline',
  backgroundColor: '$hoverBg',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  variants: {
    checked: {
      true: {
        backgroundColor: '$primaryText',
        borderColor: '$primaryText',
      },
    },
    disabled: {
      true: {
        backgroundColor: '$disabledBg',
        borderColor: '$disabledText',
        hoverStyle: {
          borderColor: '$disabledText',
        },
        cursor: 'not-allowed',
      },
    },
  } as const,
  defaultVariants: {
    disabled: false,
  },
})
